.error_boundary__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    button {
        padding: 0.5rem 1rem;
        border-radius: 8px;
        cursor: pointer;
    }
}