html, body {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-size: 16px;
  font-family: "Pretendard";
}

.App {
  height: 100%;
  overflow: hidden;
}

input, textarea {
  font-family: "Pretendard";
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  // scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, p, ul {
  margin: 0;
}

ul, li, ol {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;

  // touch 영역 보이지 않게
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

button {
  border: 0;
  outline: 0;
}

#root {
  height: 100%;
}

// Common
.More_Notice {
  width: 100%;
  text-align: center;
}

.Dot {
  margin: 0 0.5rem;
}

.Full_Screen_Modal {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #fff;

  &_Header {
    position: relative;
    padding: 1rem 1.25rem;
    border-bottom: 1px solid #f1f1f1;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.22;
    text-align: center;
    color: #000;

    > img {
      position: absolute;
      left: 1.25rem;
      width: 1.5rem;
      cursor: pointer;
    }
  }

  &_Content {
    flex: 1;
    padding: 2.5rem 1.25rem;
  }
}

// Search Keyword Modal, NeighborhoodSettings - Adding a Neighborhood Modal Auto Search Container
.Auto_Search_Container {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 9997;

  &_Background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      background-color: #101010;
      z-index: 9997;
  }

  ul {
      position: relative;
      // padding-bottom: 1.25rem;
      border: 1px solid #f1f1f1;
      background-color: #fff;
      z-index: 9998;

      li {
          display: flex;
          align-items: center;
          padding: 1rem 1.25rem;
          cursor: pointer;          

          img {
              width: 1rem;
              margin-right: 0.875rem;
          }

          p {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
      }
  }
}

// Policy, Terms
.policy, .terms, .restricted {
  display: flex;
  flex-direction: column;
  height: 100%;

  .policy__contents, .terms__contents, .restricted__contents {
    flex: 1;
    padding: 1rem;
    font-size: 0.875rem;
    overflow-y: auto;

    a {
      color: #5e53f7;
    }

    ul {
      padding: 1rem 0;

      > li {
        margin-bottom: 1rem;
        line-height: 1.2;
        
        &_title {
          margin-bottom: 0.5rem;
          font-size: 0.875rem;
        }

        > div {
          word-break: break-word;
        }

        ol {
          display: block;
          padding: 0.25rem 0 0.25rem 1.5rem;

          li {
            padding: 1px 0;
            list-style: circle;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

// Confirm Modal Style
.confirm_modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
  transform: translate(-50%, -50%);

  p {
      margin-bottom: 1rem;
  }

  .Confirm_Modal_Button_Wrapper {
      display: flex;

      span {
          flex: 1;
          display: inline-block;
          padding: 0.5rem;
          border-radius: 8px;
          border: 1px solid #e1e1e1;
          text-align: center;
          color: #000;
          cursor: pointer;

          &:last-child {
              margin-left: 1rem;
              color: #fff;
              background-color: #5e53f7;
          }

          &.red {
            background-color: #ff6b7a;
          }
      }
  }
}

// Google Maps Modal Style
.Google_Maps {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;

  #google_map{
    flex: 1;
  }

  .Place_Search_Box {
    position: relative;
    width: 100%;

    input {
      width: 100%;
    }

    ul {
      position: absolute;
      bottom: 0;
      z-index: 9999;
      background-color: #fff;
    }
  }
}

.Marker_Info {
  .Marker_Info_Content {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
  
    img {
      width: 2rem;
      margin: 0 0.5rem;;
    }
  
    .Marker_Info_Description {
      display: flex;
      flex-direction: column;
      padding: 0 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #101010;

      &.Empty_Marker {
        color: #7b7b7b;
      }
    }
  }

  .Marker_Info_Apply_Button {
    display: block;
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    font-size: 1.125rem;
    color: #f1f1f1;
    background-color: #5e53f7;

    &:disabled {
      background-color: #7b7b7b;
    }
  }
}

.Delete_Posts_Confirm_Content {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.05);

  .Confirm_Message {
      margin-bottom: 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      color: #000;
      white-space: nowrap;
  }

  .Confirm_Buttons_Wrapper {
      display: flex;
      flex-direction: row;
      
      span {
          padding: 0.75rem 2.5rem;
          border-radius: 8px;
          border: 1px solid #e5e5e5;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.31;
          letter-spacing: -0.32px;
          text-align: center;
          color: #83828a;
          cursor: pointer;
          
          &.Delete_Button {
              margin-left: 1rem;
              border: 0;
              font-weight: bold;
              color: #fff;
              background-color: #5e53f7;
          }
      }
  }
}