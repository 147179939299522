:root {
    --gray50: #F9F9F9;
    --gray100: #F9FAFB;
    --gray200: #E7E5EC;
    --gray300: #D2D1D9;
    --gray400: #C4C4C6;
    --gray500: #A1A0A7;
    --gray600: #83828A;
    --gray700: #535258;
    --gray800: #303031;
    --gray900: #101010;

    --puple100: #F4F2FF;
    --puple200: #EBE9FE;
    --puple300: #D7D4FD;
    --puple400: #C3BFFC;
    --puple500: #AFA9FB;
    --puple600: #9A94FA;
    --puple700: #867EF9;
    --puple800: #5E53F7;
    --puple900: #392DE0;

    --green100: #ECF8F5;
    --green200: #C6E2DB;
    --green300: #5FD4B8;
    --green400: #26A77F;
    --green500: #279178;
    
    --pink100: #FFF0F1;
    --pink200: #FF6B7A;
}