@font-face {
    /* 사용할 폰트의 이름 */
    font-family: "Pretendard";
    font-display: fallback;
  
    /* 
    src: 사용할 폰트의 위치 및 형식 
       Safari, Android, iOS => ttf
       Modern Browsers => woff
       IE6-IE8 => eot
    */
    src: url("./Pretendard-Regular.ttf") format("truetype"), url("./Pretendard-Regular.woff")
        format("woff"), url("./Pretendard-Regular.eot?iefix") format("embedded-opentype");
  
    /* 사용할 폰트의 기본 스타일 */
    font-weight: 400;
    font-style: normal;
  }